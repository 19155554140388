.logs {
    height: 100%;
    position: relative;

    &-header {
        padding: 32px 80px;

        &-back {
            display: flex;
            align-items: center;
            cursor: pointer;

            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #969595;

            margin-bottom: 24px;

            & svg {
                margin-right: 2px;
            }
        }

        & .title {
            margin-bottom: 13px;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;

        height: calc(100vh - 255px);

        & .block {
            overflow: auto;
        }
    }
}