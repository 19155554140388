.tag {
    padding: 8px 12px;

    background: #FFFFFF;
    border: 1px solid #CACACA;
    box-sizing: border-box;
    border-radius: 100px;

    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #201D1D;
}