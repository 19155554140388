.logo {
    display: flex;
    align-items: center;
    
    & svg {
        margin-right: 12px;
    }

    &-content {
        display: flex;
        flex-direction: column;
    }

    &-title {
        font-family: "Roboto Condensed";
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 21px;

        letter-spacing: 0.01em;
        text-transform: uppercase;

        color: #201D1D;
    }

    &-subtitle {
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        color: #969595;
    }
}