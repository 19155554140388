.select {
    &_wrapper {

    }

    &-label {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        color: #201D1D;

        &_disabled {
            opacity: 0.4;
        }
    }

    &__control {
        border-color: #CCCCCC!important;
        border-radius: 0px!important;

        &--menu-is-open {
            border-color: #201D1D!important;
            & .select__dropdown-indicator {
                transform: rotate(180deg);
            }
        }

        &--is-focused {
            border-color: #969595!important;
            box-shadow: unset!important;
        }

        &--is-disabled {
            opacity: .4;
            background-color: #fff!important;
            & .select__dropdown-indicator {
                color: #969595!important;
            }
        }
    }

    &__placeholder {
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        color: #969595!important;
    }

    &__value {
        &-container {
            padding: 12px 0px 12px 16px!important;
        }
    }

    &__indicator-separator {
        display: none;
    }

    &__input {
        outline: none;

        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #201D1D!important;
    }

    &__dropdown-indicator {
        color: #969595!important;
        &:hover {
            color: #201D1D!important;
        }
    }
}
