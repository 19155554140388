.sendedMessages {
    height: 100%;

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 32px 80px;
    }

    &-content {
        display: flex;
        flex-direction: column;

        height: calc(100vh - 182px);
    }
}