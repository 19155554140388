.deleteTemplate {
    width: 520px;

    &-header {
        text-align: center;
        margin-bottom: 32px;

        & .title {
            margin-bottom: 8px;
        }
    }

    &-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 350px;
        margin: 0 auto;

        & .button {
            &:first-child {
                margin-right: 8px;
            }

            &:last-child {
                margin-left: 8px;
            }

            width: 50%;
        }
    }
}