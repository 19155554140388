.variablesTableItem {
    &-actions {
        display: flex;
        align-items: center;

        & > div {
            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 24px;
            }
        }

        & .templatesTableItem-icon {
            margin-right: 4px;
            cursor: pointer;
        }
    }
    &-action {
        cursor: pointer;

        &:hover {
            color: #FF3214;

            & svg, path {
                fill: #FF3214;
            }
        }
    }
}