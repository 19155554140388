.input {
    outline: none;
    width: 100%;

    &-wrapper {
        position: relative;
    }

    &-error {
        position: absolute;
        bottom: -20px;

        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        color: #FF3214;
    }

    &-eye {
        float: right;
        position: relative;

        width: 24px;
        height: 24px;
        margin-top: -33px;
        margin-right: 10px;
        z-index: 2;

        cursor: pointer;   
    }

    &-label {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        color: #201D1D;

        &_disabled {
            opacity: 0.4;
        }
    }

    &_size {
        &_default {
            padding: 12px 16px;
            
            font-size: 14px;
            line-height: 20px;
        }
    }

    &_view {
        &_default {
            font-family: "Roboto";
            font-style: normal;
            font-weight: normal;

            background: #FFFFFF;

            border: 1px solid #CCCCCC;
            box-sizing: border-box;

            &:hover {
                border: 1px solid #969595;
            }

            &::placeholder {
                font-family: "Roboto";
                font-style: normal;
                font-weight: normal;
                color: #969595;
            }

            &:disabled {
                opacity: 0.4;
            }
        }
    }

    &_invalid {
        background: rgba(255, 50, 20, 0.05);
        border: 1px solid #FF3214;
    }
}