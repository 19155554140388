.changeVariable {
    width: 500px;

    &-header {
        margin-bottom: 40px;
    }

    &-form {
        & .input {
            max-width: 100%;
            min-width: 100%;
            resize: none;
        }

        & .input-wrapper {
            
            &:not(:last-child) {
                margin-bottom: 24px;
            }

            &:last-child {
                margin-bottom: 32px;
            }
        }
    }

    &-actions {
        & .button {
            width: 100%;
        }
    }
}