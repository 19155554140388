.logsTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;

    &-scroll {
        height: calc(100% - 150px);
        overflow: auto;

        margin-bottom: 32px;
    }

    &-searchItem {
        margin-right: 16px;
    }
}