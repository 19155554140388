.messages {
    height: 100%;
    &-header {
        padding: 32px 80px;
    }

    &-back {
        cursor: pointer;

        display: flex;
        align-items: center;

        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        color: #969595;
        
        & svg {
            margin-right: 9px;
        }

        margin-bottom: 24px;
    }

    &-content {
        display: flex;
        flex-direction: column;

        height: calc(100vh - 148px - 72px);

        & .block {
            padding-right: 0px;
        }
    }
}