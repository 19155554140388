.title {
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    color: #201D1D;
    
    &_size {
        &_l {
            
            font-size: 36px;
            line-height: 44px;
        }

        &_m {
            font-size: 32px;
            line-height: 40px;
        }

        &_xs {
            font-size: 24px;
            line-height: 30px;
        }

        &_xxs {
            font-size: 20px;
            line-height: 30px;
        }
    }
}