.templatesHistory {
    height: 100%;
    overflow: auto;

    &-icon {
        &:hover {
            color: #FF3214;
            & svg, path {
                fill: #FF3214;
            }
        }
    }

    &-header {
        padding: 32px 80px;
    }

    &-back {
        display: flex;
        align-items: center;
        cursor: pointer;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #969595;

        margin-bottom: 24px;

        & svg {
            margin-right: 7px;
        }
    }

    & .title {
        margin-bottom: 13px;
    }

    &-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */


        /* Dark Grey */

        color: #969595;

    }

    &-action {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #201D1D;


        &:hover {
            color: #FF3214;

            & .templatesHistory-icon {
                color: #FF3214;

                & svg, path {
                    fill: #FF3214;
                }
            }
        }
    }

    &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    &-actions {
        display: flex;
        align-items: center;

        & > div {
            display: flex;
            align-items: center;
            cursor: pointer;

            &:first-child {
                margin-right: 24px;
            }
        }

        & .templatesHistory-icon {
            margin-right: 4px;
        }
    }

    &-template {
        padding: 32px 80px;

        margin-bottom: 32px;
        
        background-color: #fff;
    }

    &-templateTitle {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;

        color: #201D1D;

        margin-bottom: 16px;
    }

    &-templateText {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        color: #201D1D;
    }
}