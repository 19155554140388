.header {
    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.04));
    background-color: #ffffff;

    padding: 0px 60px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 72px;

    &-item {
    }

    &-navigation {
        display: flex;
        align-items: center;
        height: 100%;

        & a {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            text-decoration: none;

            font-family: "Roboto";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;

            color: #969595;

            &:hover {
                color: #FF3214!important;
            }

            &:not(:last-child) {
                margin-right: 40px;
            }
        }

        &_checked {
            color: #201D1D!important;

            border-bottom: 2px solid #FF3214;
        }
    }

    &-profile {
        display: flex;
        align-items: center;

        & .header-name {
            margin-right: 10px;
        }
    }

    &-name {
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        color: #201D1D;
    }

    &-icon {
        cursor: pointer;
    }

}