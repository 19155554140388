.table {
    & tr {
        border-bottom: 1px solid#F3F3F3;
    }
    
    & tbody tr:hover {
        background-color: #F9F9F9;
        cursor: pointer;
    }
    & tr th:first-child {
        padding-left: 24px;
    }

    & tr th:last-child {
        padding-right: 24px;
    }

    & tr td:first-child {
        padding-left: 24px;
    }

    & tr td:last-child {
        padding-right: 24px;
    }

    & th, td {
        padding-top: 13px;
        padding-bottom: 14px;
    }
}