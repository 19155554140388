.unsubscribe {
    width: 500px;

    & .subtitle {
        margin-top: 8px;
        text-align: left;
    }

    &-header {
        margin-bottom: 40px;
    }

    &-form {
        margin-bottom: 32px;
        &-title {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            color: #969595;

            &_first {
                width: 40%;
            }

            &_second {
                width: 60%;
            }
        }
    }

    &-field {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 16px;
    }

    &-label {
        width: 40%;
        
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #000000;
    }

    &-select {
        width: 60%;
    }

    &-actions {
        margin-bottom: 30px;

        & .button {
            width: 100%;
        }
    }


    &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #201D1D;

        padding-bottom: 9px;
        border-bottom: 1px solid #EEEEEE;

        &:not(:last-child) {
            margin-bottom: 9px;
        }
        
        & svg, path {
            fill: #000;
        }

        & svg:hover {
            cursor: pointer;

            & path {
                fill: #FF3214;
            }
        }
    }
}