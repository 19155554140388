.adminsTableItem {
    border-bottom: 1px solid #F3F3F3;

    &:hover {
        background: #F9F9F9;
    }

    &-token {
        display: flex;
        align-items: center;

        & .adminsTableItem-icon {
            margin-left: 15px;
            cursor: pointer;
        }
    }

    &-actions {
        display: flex;
        align-items: center;

        & > div {
            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 24px;
            }
        }

        & .adminsTableItem-icon {
            margin-right: 4px;
            cursor: pointer;
        }
    }

    &-icon {
        &:hover {
            color: #FF3214;
            & svg, path {
                fill: #FF3214;
            }
        }
    }

    &-action {
        cursor: pointer;

        &:hover {
            color: #FF3214;

            & svg, path {
                fill: #FF3214;
            }
        }
    }
}