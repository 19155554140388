.addGroup {
    width: 500px;
    &-header {
        margin-bottom: 40px;

        & div {
            text-align: left;
            
            &:first-child {
                margin-bottom: 8px;
            }
        }
    }

    &-input {
        margin-bottom: 32px;
    }

    & .button {
        width: 100%;
    }
}