.logsInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #969595;
    }

    &-row {
        display: flex;
        align-items: center;
    }

    &-dot {
        margin: 0px 16px
    }


    &-token {
        display: flex;
        align-items: center;

        margin-left: 8px;

        & .logsInfo-icon {
            margin-left: 15px;
            cursor: pointer;
        }
    }

    &-icon {
        &:hover {
            color: #FF3214;
            & svg, path {
                fill: #FF3214;
            }
        }
    }

    &-action {
        &:hover {
            color: #FF3214;

            & .logsInfo-icon {
                color: #FF3214;
                & svg, path {
                    fill: #FF3214;
                }
            }
        }
    }


    &-actions {
        display: flex;
        align-items: center;

        & > div {
            display: flex;
            align-items: center;
            cursor: pointer;

            &:first-child {
                margin-right: 24px;
            }
        }

        & .logsInfo-icon {
            margin-right: 4px;
        }
    }
}