.pagination {
    display: flex;
    align-items: center;

    &-item {
        width: 32px;
        height: 32px;
    
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 0px;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        color: #201D1D;
        

        &:hover {
            background: #F3F3F3;
        }

        &:not(:last-child) {
            margin-right: 8px;
        }

        &_selected {
            border: 1px solid #FF3214;
        }
    }
}