.templates {
    height: 100%;
    &-row {
        display: flex;
        align-items: center;
        
        & .button_view_primary {
            margin-right: 15px;
        }
    }
    
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 32px 80px;
    }

    &-content {
        display: flex;
        flex-direction: column;

        height: 100%;
    }
}