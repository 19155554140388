.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid transparent;
    cursor: pointer;
    font-family: 'Roboto Condensed';

    &_size {
        &_m {
            padding: 12px 24px;

            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.3px;
        }
    }

    &_view {
        &_primary {
            background: #FF3214;
            font-style: normal;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;

            &:hover {
                background: #EA3014;


                & path {
                    fill: #EA3014;
                }
            }

            &:disabled {
                background: #F3F3F3;
                opacity: 0.4;
                border: 1px solid #CCCCCC;
                color: #201D1D;
            }
        }

        &_secondary {
            background: #FFFFFF;
            /* Color / Red */
            font-style: normal;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;

            border: 1px solid #FF3214;
            box-sizing: border-box;
            color: #FF3214;

            &:hover {
                background: #FF3214;
                color: #FFFFFF;

                & path {
                    fill: #ffffff;
                }
            }

            &:disabled {
                background: #F3F3F3;
                opacity: 0.4;
                border: 1px solid #CCCCCC;
                color: #201D1D;
            }
        }
    }
}