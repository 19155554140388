.messagesTable {
    width: 100%;
    border-collapse: collapse;

    &-scroll {
        height: calc(100% - 280px);
        padding-right: 80px;
        overflow-y: auto;

        margin-bottom: 32px;
    }

    &-title {
        font-family: "Roboto Condensed";
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;

        text-transform: uppercase;

        color: #201D1D;

        margin-bottom: 24px;
    }
}