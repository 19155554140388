.sendedMessagesTableItem {
    &-title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        color: #201D1D;
    }

    &-text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        color: #201D1D;
    }

    &-tags {
        display: flex;
        flex-wrap: wrap;

        & .tag:not(:last-child) {
            margin-right: 8px;
        }
    }
}