.subtitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    text-align: center;

    color: #969595;
    
    &_size {
        &_m {
            font-size: 14px;
            line-height: 20px;
        }
    }
}