.modal {
    display: none;

    &_show {
        position: fixed;

        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
    
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    
        z-index: 1000;
    
        background-color: rgba(0,0,0,.1);
    }

    &-content {
        position: relative;
        background-color: #fff;
        padding: 30px;
    }

    &-close {
        position: absolute;
        right: 29px;
        top: 29px;
        font-size: 28px;
        line-height: 14px;
        cursor: pointer;
    }
}