.sendedMessagesSearch {
    display: flex;

    margin-bottom: 32px;


    &-item {
        width: 20%;
        
        &:not(:last-child) {
            margin-right: 16px;
        }

        & .input-wrapper, .input {
            height: 53px;
        }
    }
}