.changeEvent {
    width: 700px;

    &-header {
        margin-bottom: 40px;
    }

    &-event {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EEEEEE;
        padding: 9px 0px;

        &-form {
            .select-wrapper {
                margin-bottom: 8px!important;
            }
        }

        & span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #201D1D;
        }
    }


    &-form {
        &_size {
            &_medium {
                width: 60%;
            }
        }
        & .input {
            max-width: 100%;
            min-width: 100%;
            resize: none;
        }

        & .select-wrapper {
            margin-bottom: 24px;
        }

        & .input-wrapper {

            &:not(:last-child) {
                margin-bottom: 24px;
            }

            &:last-child {
                margin-bottom: 32px;
            }
        }
    }

    &-trash {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            & path {
                fill: #FF3214;
            }
        }
    }

    &-condition {
        &-header {
            display: flex;
            justify-content: space-between;

            margin-bottom: 5px;
        }

        & .subtitle {
            text-align: left;
        }

        &-form {
            margin-top: 16px;

            &-row {
                display: flex;
                justify-content: space-between;

                & .addMailing-trash {
                    margin-bottom: 24px;
                }

                & .input {
                    height: 53.01px;
                }

                & > *:not(:last-child) {
                    width: 30%;
                }
            }
        }
    }

    &-addCondition {
        cursor: pointer;

        display: flex;
        align-items: center;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        & svg {
            margin-right: 5px;

            & path {
                fill: #000;
            }
        }

        &:hover {
            color: #FF3214;

            & path {
                fill: #FF3214;
            }
        }
    }

    &-actions {
        & .button {
            width: 100%;
        }
    }
}
