.templatesHistoryTable {
    margin-bottom: 32px;
    width: 100%;

    &-scroll {
        margin-bottom: 32px;
    }
    
    &-search {
        margin-bottom: 30px;
        width: 20%;
    }

    &-title {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height, or 125% */

        text-transform: uppercase;

        /* Black */

        color: #201D1D;

        margin-bottom: 32px;
    }
}