.layout {
    background: #F9F9F9;

    height: 100vh;

    display: flex;
    flex-direction: column;
}

.content {
    height: calc(100% - 72px);
    position: relative;
    overflow: hidden;
}