.auth {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 100%;

    & .title {
        margin-bottom: 8px;
    }

    & .subtitle {
        margin-bottom: 24px;
    }
    
    &-form {
        display: flex;

        & .input {
            width: 323px;
        }
    }
}