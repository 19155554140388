.eventsTable {
    margin-bottom: 32px;
    width: 100%;

    &-scroll {
        height: calc(100% - 280px);
        padding-right: 80px;
        overflow-y: auto;

        margin-bottom: 32px;
    }
    
    &-search {
        margin-bottom: 30px;
        width: 60%;

        display: flex;

        & .input-wrapper {
            width: 60%;
            & .input {
                height: 54px;
            }
        }

        & .select__control {
            height: 54px;
            margin-right: 16px;
        }
    }
}