.receiversTable {
    width: 100%;
    border-collapse: collapse;

    margin-bottom: 32px;

    &-search {
        margin-bottom: 32px;
        display: flex;
        width: 50%;
        & .input-wrapper {
            width: 60%;
        }

        & .select-wrapper {
            padding-right: 16px;

            width: 40%;
        }

        & .input, .select__control {
            height: 54px;
        }
    }
}