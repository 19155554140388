.sendedMessagesTable {
    width: 100%;
    border-collapse: collapse;

    &-scroll {
        height: calc(100% - 200px);
        padding-right: 80px;
        overflow-y: auto;

        margin-bottom: 32px;
    }
}